export const ProposalConst = {

    proposalBy: {
        COMPANY: 'COMPANY',
        STANDARD: 'STANDARD',
    },
    surChargeType: {
        FIX: 'FIX',
        PERCENT: 'PERCENT',
    },
    roundMethod: {
        ROUND: 'ROUND',
        ROUND_DOWN: 'ROUND DOWN',
        ROUND_UP: 'ROUND UP',
    },
    decimalPlace: {
        0: '0',
        1: '1',
        2: '2',
    },
}