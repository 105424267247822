import { ChargeOnCompanyMappingEntity, EMPTY_CHARGE_ON_COMPANY_MAPPING_ENTITY } from "domain/entity/ChargeOnCompanyMapping/ChargeOnCompanyMappingEntity";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";


export interface ChargeOnCompanyMappingDropdownOptions {
    chargeTypeDropdownOptions: DropdownProps[],
    subChargeTypeDropdownOptions: { [key: string]: DropdownProps[] },
    operatingTmlDropdownOptions: DropdownProps[],
    serviceCodeDropdownOptions: DropdownProps[],
    soaDropdownOptions: DropdownProps[],
    opsLineDropdownOptions: DropdownProps[],
    chargeOnCompanyDropdownOptions: DropdownProps[],
    marshallingTypeDropdownOptions: DropdownProps[],
    specHandingIndDropdownOptions: DropdownProps[],
    vesselTypeDropdownOptions: DropdownProps[],
    consortiumCodeDropdownOptions: DropdownProps[],
    forwarderCodeDropdownOptions: DropdownProps[],
    chargeCodeDropdownOptions: DropdownProps[],
    marshalCodeDropdownOptions: DropdownProps[],
    modalityDropdownOptions: DropdownProps[],
}
export interface ChargeOnCompanyMappingViewChangeState extends BaseViewChangeSate {
}

export interface ChargeOnCompanyMappingModel {
    isLoading:boolean,
    isShowEditPanel:boolean,
    isAdd:boolean,
    isEdit:boolean,
    dynamicOptions: ChargeOnCompanyMappingDropdownOptions,
    chargeOnCompanyMappingList: ChargeOnCompanyMappingEntity[],
    updatedRows: ChargeOnCompanyMappingEntity[],
    selectedRows: ChargeOnCompanyMappingEntity[],
    currentRow: ChargeOnCompanyMappingEntity,
    currentEditRow: ChargeOnCompanyMappingEntity,
    
}

export const EMPTY_CHARGE_ON_COMPANY_MAPPING_MODEL: ChargeOnCompanyMappingModel = {
    isLoading: false,
    isShowEditPanel: false,
    isAdd: false,
    isEdit: false,
    dynamicOptions: {
        chargeTypeDropdownOptions: [],
        subChargeTypeDropdownOptions: {},
        operatingTmlDropdownOptions: [],
        serviceCodeDropdownOptions: [],
        soaDropdownOptions: [],
        opsLineDropdownOptions: [],
        chargeOnCompanyDropdownOptions: [],
        marshallingTypeDropdownOptions: [],
        specHandingIndDropdownOptions: [],
        vesselTypeDropdownOptions: [],
        consortiumCodeDropdownOptions: [],
        forwarderCodeDropdownOptions: [],
        chargeCodeDropdownOptions: [],
        marshalCodeDropdownOptions: [],
        modalityDropdownOptions: []
    },
    chargeOnCompanyMappingList: [],
    updatedRows: [],
    selectedRows: [],
    currentRow: {...EMPTY_CHARGE_ON_COMPANY_MAPPING_ENTITY},
    currentEditRow: {...EMPTY_CHARGE_ON_COMPANY_MAPPING_ENTITY}
}