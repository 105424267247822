export const ReportConst = {
    month: {
        "01": "01",
        "02": "02",
        "03": "03",
        "04": "04",
        "05": "05",
        "06": "06",
        "07": "07",
        "08": "08",
        "09": "09",
        "10": "10",
        "11": "11",
        "12": "12",
    },
    exportType: {
        PDF: "PDF",
        CSV: "CSV",
        RTF: "RTF",
    },
    proposalType: {
        STANDARD: "Standard",
        GN: "GN",
        BOTH: "Both",
    },
    versionType: {
        NORMAL: "Normal",
        DIFFERENCE: "Difference",
    },
    exceptionType: {
        CHARGE_GEN: "CHARGE_GEN",
        DOC_GEN: "DOC_GEN",
        SIM_DOC_GEN: "SIM_DOC_GEN",
        // SIM_CHARGE_GEN: "SIM_CHARGE_GEN",
        // DOC_PRE: "DOC_PRE",
        DOC_ISSUE: "DOC_ISSUE",
        // DOC_ADJ: "DOC_ADJ",
        DOC_FINALIZE: "DOC_FINALIZE",
        RCL_GEN: "RCL_GEN",
        RECAL_ASSIGN: "RECAL_ASSIGN",
        GL_GEN: "GL_GEN",
        RECAL_GEN: "RECAL_GEN",
        AUTOMATED_COKD: "AUTOMATED_COKD",
    }
}
