export const ChargeOnBillToCompanyPolicy =() => {

    const ITEM_KEY_SOA = "SOA";
	const ITEM_KEY_OPS_LINE = "Ops Line";
	const ITEM_KEY_FORWARDER = "Forwarder";
	const ITEM_KEY_OWNER_DEP = "Ops Line(Departure)";
	const ITEM_KEY_SOA_ARRIVAL = "SOA(Arrival)";
	const ITEM_KEY_SOA_DEP = "SOA(Departure)";

    const ITEM_VAL_SOA = "BILL_TO_SOA";
	const ITEM_VAL_OPS_LINE = "BILL_TO_OWNER";
	const ITEM_VAL_FORWARDER = "BILL_TO_FORWARDER";
	const ITEM_VAL_OWNER_DEP = "BILL_TO_OWNER_DEP";
	const ITEM_VAL_SOA_ARRIVAL = "BILL_TO_SOA_ARRIVAL";
	const ITEM_VAL_SOA_DEP = "BILL_TO_SOA_DEP";



    
    const getChargeOnBillToCompanyValueByKey = (text: string) => {
        if(!text){
            return null;
        }
        if (ITEM_KEY_SOA === text) {
            return ITEM_VAL_SOA;
        } else if (ITEM_KEY_OPS_LINE === text) {
            return ITEM_VAL_OPS_LINE;
        } else if (ITEM_KEY_FORWARDER === text) {
            return ITEM_VAL_FORWARDER;
        } else if (ITEM_KEY_OWNER_DEP === text) {
            return ITEM_VAL_OWNER_DEP;
        } else if (ITEM_KEY_SOA_ARRIVAL === text) {
            return ITEM_VAL_SOA_ARRIVAL;
        } else if (ITEM_KEY_SOA_DEP === text) {
            return ITEM_VAL_SOA_DEP;
        } 
        return text;
    }
    const getChargeOnBillToCompanyKeyByValue = (text: string) => {
        if(!text){
            return null;
        }
        if (ITEM_VAL_SOA === text) {
            return ITEM_KEY_SOA;
        } else if (ITEM_VAL_OPS_LINE === text) {
            return ITEM_KEY_OPS_LINE;
        } else if (ITEM_VAL_FORWARDER === text) {
            return ITEM_KEY_FORWARDER;
        } else if (ITEM_VAL_OWNER_DEP === text) {
            return ITEM_KEY_OWNER_DEP;
        } else if (ITEM_VAL_SOA_ARRIVAL === text) {
            return ITEM_KEY_SOA_ARRIVAL;
        } else if (ITEM_VAL_SOA_DEP === text) {
            return ITEM_KEY_SOA_DEP;
        } 
        return text;
    }
    return {
        getChargeOnBillToCompanyKeyByValue: getChargeOnBillToCompanyKeyByValue,
        getChargeOnBillToCompanyValueByKey: getChargeOnBillToCompanyValueByKey,
    }
}
