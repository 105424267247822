export const MasterGroupType = {
    COMPANY_GROUP: "CompanyGroupEntity",
    CONSORTIUM_GROUP: "ConsortiumGroupEntity",
    SERVICE_GROUP: "ServiceGroupEntity",
}
export const MasterGroupAlias = [
    "Company Group",
    "Consortium Group",
    "Service Group",
]

export interface GroupEntity {
    id: number,
    groupCode: string,
    groupDesc: string | null,
    groupType: string | null,
    members: string[] | null,
    groupMembers?: string | null,
    activeInd: string | null,
    [key: string]: string | number | string[] | null | undefined
}
export const EMPTY_GROUP_ENTITY: GroupEntity = {
    id: 0,
    groupCode: "",
    groupDesc: null,
    groupType: null,
    members: [],
    activeInd: "Y"
} 
