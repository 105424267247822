import { VesselTypeEntity } from "domain/entity/VesselType/VesselTypeEntity";
import { axiosGetData } from "../axios/AxiosBasicImpl";
import referenceAxiosInstance from "../axios/referenceAxiosInstance";
import { VesselTypeRepository } from "./VesselTypeRepo";

export const VesselTypeRepoImpl = ():VesselTypeRepository => {
    const url = '/v1/vesselType/getVesselTypeForCombobox'
    const getVesselTypeForComboBox = async() : Promise<VesselTypeEntity[]> => {
        return axiosGetData(referenceAxiosInstance, url,[]).then(res => {
            return res.data;
        }).catch(err => {
            return [];
        })
    }

    return{
        getVesselTypeForComboBox: getVesselTypeForComboBox
    }
}