import { ActiveInd } from "constants/ActiveInd";
import { ChargeConstantCollection } from "constants/charge/ChargeConstantCollection";
import { DocumentConst } from "constants/document/DocumentConst";
import { ReportConst } from "constants/document/ReportConst";
import { ApprovalProcessStatusPolicy } from "constants/proposal/ApprovalProcessStatusPolicy";
import { ProposalConst } from "constants/proposal/ProposalConst";
import { MasterGroupAlias } from "domain/entity/Group/GroupEntity";
import { MasterCodeAlias } from "domain/entity/MasterData/MasterDataEntity";
import _ from "lodash";
import { DropdownProps } from "presentation/model/DropdownProps";
import { eventType, ShiftDay, ShiftType } from "../Common/ShiftType";

const stateList: { [key: string]: string; } = ChargeConstantCollection.dtlSate;
export const stateDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(stateList), ([key, value]) => (
            { dropdownLabel: value, tagLabel: value, value: key }
        )
    ) ?? [], "dropdownLabel"))
];

const hdrStateList: { [key: string]: string; } = ChargeConstantCollection.hdrState;
export const hdrStateDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(hdrStateList), ([key, value]) => (
            { dropdownLabel: value, tagLabel: value, value: value }
        )
    ) ?? [], "dropdownLabel"))
];

export const manHdrStateList: { [key: string]: string; } = ChargeConstantCollection.manHdrState;
export const manHdrStateDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(manHdrStateList), ([key, value]) => (
            { dropdownLabel: value, tagLabel: value, value: value }
        )
    ) ?? [], "dropdownLabel"))
];


const billingTypeList: { [key: string]: string; } = ChargeConstantCollection.billingType;
export const billingTypeDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(billingTypeList), ([key, value]) => (
            { dropdownLabel: value, tagLabel: value, value: value }
        )
    ) ?? [], "dropdownLabel"))
];

const manSysList: { [key: string]: string; } = ChargeConstantCollection.systemManualInd;
export const manSysDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(manSysList), ([key, value]) => (
            { dropdownLabel: value, tagLabel: value, value: value }
        )
    ) ?? [], "dropdownLabel"))
];

const operationTypeList: { [key: string]: string; } = ChargeConstantCollection.operationType;
export const operationTypeDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(operationTypeList), ([key, value]) => (
            { dropdownLabel: value, tagLabel: value, value: value }
        )
    ) ?? [], "dropdownLabel"))];

const bundleIndList: { [key: string]: string; } = ChargeConstantCollection.bundleInd;
export const bundleIndDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(bundleIndList), ([key, value]) => (
            { dropdownLabel: value, tagLabel: value, value: value }
        )
    ) ?? [], "dropdownLabel"))];

const modalityList: { [key: string]: string; } = ChargeConstantCollection.modality;
export const modalityDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(modalityList), ([key, value]) => (
            { dropdownLabel: value, tagLabel: value, value: value }
        )
    ) ?? [], "dropdownLabel"))];

const fullEmptyList: { [key: string]: string; } = ChargeConstantCollection.emptyFullInd;
export const fullEmptyDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(fullEmptyList), ([key, value]) => (
            { dropdownLabel: value, tagLabel: value, value: value }
        )
    ) ?? [], "dropdownLabel"))];

const emptyIndList: { [key: string]: string; } = ChargeConstantCollection.emptyInd;
export const emptyIndDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(emptyIndList), ([key, value]) => (
            { dropdownLabel: value, tagLabel: value, value: value }
        )
    ) ?? [], "dropdownLabel"))];

const endVisitIndList: { [key: string]: string; } = ActiveInd;
export const endVisitIndDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(endVisitIndList), ([key, value]) => (
            { dropdownLabel: value, tagLabel: value, value: value }
        )
    ) ?? [], "dropdownLabel"))];

const vesselTypeList: { [key: string]: string; } = ChargeConstantCollection.vesselType;
export const vesselTypeDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(vesselTypeList), ([key, value]) => (
            { dropdownLabel: value, tagLabel: value, value: value }
        )
    ) ?? [], "dropdownLabel"))];

const adjTypeList: { [key: string]: string; } = ChargeConstantCollection.adjType;
export const adjTypeDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(adjTypeList), ([key, value]) => (
            { dropdownLabel: value, tagLabel: value, value: key }
        )
    ) ?? [], "dropdownLabel"))];

const adjTypeListForSearch: { [key: string]: string; } = ChargeConstantCollection.adjTypeForSearch;
export const adjTypeForSearchDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(adjTypeListForSearch), ([key, value]) => (
            { dropdownLabel: value, tagLabel: value, value: value }
        )
    ) ?? [], "dropdownLabel"))];

const adjTypeNoRecalList: { [key: string]: string; } = ChargeConstantCollection.adjTypeNoRecal;
export const adjTypeNoRecalDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(adjTypeNoRecalList), ([key, value]) => (
            { dropdownLabel: value, tagLabel: value, value: key }
        )
    ) ?? [], "dropdownLabel"))];

const cntrTspStatusList: { [key: string]: string; } = ChargeConstantCollection.cntrTspStatus;
export const cntrTspStatusDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(cntrTspStatusList), ([key, value]) => (
            { dropdownLabel: value, tagLabel: value, value: value }
        )
    ) ?? [], "dropdownLabel"))];

const rfTempUnitList: { [key: string]: string; } = ChargeConstantCollection.rfTempUnit;
export const rfTempUnitDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(rfTempUnitList), ([key, value]) => (
            { dropdownLabel: value, tagLabel: value, value: value }
        )
    ) ?? [], "dropdownLabel"))];

const cntrStatusList: { [key: string]: string; } = ChargeConstantCollection.cntrStatus;
export const statusDropdownOptions: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(cntrStatusList), ([key, value]) => (
            { dropdownLabel: value, tagLabel: value, value: value }
        )
    ) ?? [], "dropdownLabel"))];

const ignoreCntrStatusValidList: { [key: string]: string; } = ChargeConstantCollection.ignoreCntrStatusValid;
export const ignoreCntrStatusValidDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(ignoreCntrStatusValidList), ([key, value]) => (
            { dropdownLabel: value, tagLabel: value, value: value }
        )
    ) ?? [], "dropdownLabel"))];

const manualTsLegChargeIndList: { [key: string]: string; } = ChargeConstantCollection.manualTsLegChargeInd;
export const manualTsLegChargeIndDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(manualTsLegChargeIndList), ([key, value]) => (
            { dropdownLabel: value, tagLabel: value, value: value }
        )
    ) ?? [], "dropdownLabel"))];

const deliveryStatusList: { [key: string]: string; } = ChargeConstantCollection.deliveryStatus;
export const deliveryStatusDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(deliveryStatusList), ([key, value]) => (
            { dropdownLabel: value, tagLabel: value, value: value }
        )
    ) ?? [], "dropdownLabel"))];

//add company code DropdownOption for copy common account
const companyCodeList: { [key: string]: string; } = ChargeConstantCollection.companyCode;
export const companyCodeDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(companyCodeList), ([key, value]) => (
            { dropdownLabel: value, tagLabel: value, value: value }
        )
    ) ?? [], "dropdownLabel"))];
//add UOM DropdownOption for bulk edit
const uomList: { [key: string]: string; } = ChargeConstantCollection.uom;
export const uomDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(uomList), ([key, value]) => (
            { dropdownLabel: value, tagLabel: value, value: value }
        )
    ) ?? [], "dropdownLabel"))];
//add COKD Type DropdownOption for change Key Data
const cokdTypeList: { [key: string]: string; } = ChargeConstantCollection.cokdType;
export const cokdTypeDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(cokdTypeList), ([key, value]) => (
            { dropdownLabel: value, tagLabel: value, value: value }
        )
    ) ?? [], "dropdownLabel"))];
//add COKD Sub Type DropdownOption for change Key Data
const cokdSubTypeList: { [key: string]: string; } = ChargeConstantCollection.cokdSubType;
export const cokdSubTypeDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(cokdSubTypeList), ([key, value]) => (
            { dropdownLabel: value, tagLabel: value, value: value }
        )
    ) ?? [], "dropdownLabel"))];

const vatPaymentReferenceDateList: { [key: string]: string; } = DocumentConst.vatPaymentRefDateType;
export const vatPaymentReferenceDateDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(vatPaymentReferenceDateList), ([key, value]) => (
            { dropdownLabel: value, tagLabel: value, value: value }
        )
    ) ?? [], "dropdownLabel"))];

const billingCycleList: { [key: string]: string; } = ChargeConstantCollection.billingCycle;
export const billingCycleDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(billingCycleList), ([key, value]) => (
            { dropdownLabel: value, tagLabel: value, value: value }
        )
    ) ?? [], "dropdownLabel"))
];

const directDischargeIndList: { [key: string]: string; } = ChargeConstantCollection.directDischargeInd;
export const directDischargeIndDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(directDischargeIndList), ([key, value]) => (
            { dropdownLabel: key, tagLabel: key, value: value }
        )
    ) ?? [], "dropdownLabel"))
];

const ediMonitoringStatusList: { [key: string]: string; } = DocumentConst.ediMonitoringStatus;
export const ediMonitoringStatusDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(ediMonitoringStatusList), ([key, value]) => (
            { dropdownLabel: value, tagLabel: key, value: value }
        )
    ) ?? [], "dropdownLabel"))
];

const ediIssueTypeList: { [key: string]: string; } = DocumentConst.issueType;
export const ediIssueTypeDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(ediIssueTypeList), ([key, value]) => (
            { dropdownLabel: value, tagLabel: value, value: value }
        )
    ) ?? [], "dropdownLabel"))
];

const approvalProcessStatusList: { [key: string]: string; } = ApprovalProcessStatusPolicy;
export const approvalProcessStatusDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(approvalProcessStatusList), ([key, value]) => (
            { dropdownLabel: value, tagLabel: value, value: value }
        )
    ) ?? [], "dropdownLabel"))
];

const proposalByList: { [key: string]: string; } = ProposalConst.proposalBy;
export const proposalByDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(proposalByList), ([key, value]) => (
            { dropdownLabel: value, tagLabel: value, value: value }
        )
    ) ?? [], "dropdownLabel"))
];

const surChargeTypeList: { [key: string]: string; } = ProposalConst.surChargeType;
export const surChargeTypeDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(surChargeTypeList), ([key, value]) => (
            { dropdownLabel: value, tagLabel: value, value: value }
        )
    ) ?? [], "dropdownLabel"))
];

const roundMethodList: { [key: string]: string; } = ProposalConst.roundMethod;
export const roundMethodDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(roundMethodList), ([key, value]) => (
            { dropdownLabel: value, tagLabel: value, value: value }
        )
    ) ?? [], "dropdownLabel"))
];

const decimalPlaceList: { [key: string]: string; } = ProposalConst.decimalPlace;
export const decimalPlaceDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(decimalPlaceList), ([key, value]) => (
            { dropdownLabel: value, tagLabel: value, value: value }
        )
    ) ?? [], "dropdownLabel"))
];

const changeOfVslIndList: { [key: string]: string; } = ChargeConstantCollection.changeOfVslInd;
export const changeOfVslIndDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(changeOfVslIndList), ([key, value]) => (
            { dropdownLabel: value, tagLabel: value, value: value }
        )
    ) ?? [], "dropdownLabel"))
];
export const masterCodeDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(MasterCodeAlias), ([key, value]) => (
            { dropdownLabel: value, tagLabel: value, value: value }
        )
    ) ?? [], "dropdownLabel"))
];

export const masterGroupDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(MasterGroupAlias), ([key, value]) => (
            { dropdownLabel: value, tagLabel: value, value: value }
        )
    ) ?? [], "dropdownLabel"))
];

const commonAcTypeList: { [key: string]: string; } = ChargeConstantCollection.commonAcType;
export const commonAcTypeDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(commonAcTypeList), ([key, value]) => (
            { dropdownLabel: value, tagLabel: value, value: value }
        )
    ) ?? [], "dropdownLabel"))
];
const shiftTypeList: { [key: string]: string; } = ShiftType;
export const shiftTypeDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(shiftTypeList), ([key, value]) => (
            { dropdownLabel: value, tagLabel: value, value: value }
        )
    ) ?? [], "dropdownLabel"))
];
const shiftDayList: { [key: string]: string; } = ShiftDay;
export const shiftDayDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(shiftDayList), ([key, value]) => (
            { dropdownLabel: value, tagLabel: value, value: value }
        )
    ) ?? [], "dropdownLabel"))
];
const eventTypeList: { [key: string]: string; } = eventType;
export const eventTypeDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(eventTypeList), ([key, value]) => (
            { dropdownLabel: value, tagLabel: value, value: value }
        )
    ) ?? [], "dropdownLabel"))
];
const ReeferHandlingTypeList: { [key: string]: string; } = ChargeConstantCollection.ReeferHandlingType;
export const reeferHandlingTypeDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(ReeferHandlingTypeList), ([key, value]) => (
            { dropdownLabel: value, tagLabel: value, value: value }
        )
    ) ?? [], "dropdownLabel"))
];
const directDischargeIndValList: { [key: string]: string; } = ChargeConstantCollection.directDischargeIndVal;
export const directDischargeIndValDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(directDischargeIndValList), ([key, value]) => (
            { dropdownLabel: key, tagLabel: key, value: value }
        )
    ) ?? [], "dropdownLabel"))
];
const rcTypeList: { [key: string]: string; } = ChargeConstantCollection.rcType;
export const rcTypeDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(rcTypeList), ([key, value]) => (
            { dropdownLabel: key, tagLabel: key, value: value }
        )
    ) ?? [], "dropdownLabel"))
];
const discountTypeList: { [key: string]: string; } = ChargeConstantCollection.discountType;
export const discountTypeDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(discountTypeList), ([key, value]) => (
            { dropdownLabel: key, tagLabel: key, value: value }
        )
    ) ?? [], "dropdownLabel"))
];
const correctionList: { [key: string]: string; } = ChargeConstantCollection.correction;
export const correctionDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(correctionList), ([key, value]) => (
            { dropdownLabel: key, tagLabel: key, value: value }
        )
    ) ?? [], "dropdownLabel"))
];
const docTypeList: { [key: string]: string; } = DocumentConst.docType;
export const docTypeDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(docTypeList), ([key, value]) => (
            { dropdownLabel: key, tagLabel: key, value: value }
        )
    ) ?? [], "dropdownLabel"))
];

const collectionTypeList: { [key: string]: string; } = DocumentConst.collectionTypePolicy;
export const collectionTypeDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(collectionTypeList), ([key, value]) => (
            { dropdownLabel: key, tagLabel: key, value: value }
        )
    ) ?? [], "dropdownLabel"))
];

const exportTypeList: { [key: string]: string; } = ReportConst.exportType;
export const exportTypeDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(exportTypeList), ([key, value]) => (
            { dropdownLabel: key, tagLabel: key, value: value }
        )
    ) ?? [], "dropdownLabel"))
];
const monthList: { [key: string]: string; } = ReportConst.month;
export const monthDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(monthList), ([key, value]) => (
            { dropdownLabel: value, tagLabel: value, value: value }
        )
    ) ?? [], "dropdownLabel"))
];

const ediViewList: { [key: string]: string; } = DocumentConst.ediExportType;
export const ediViewDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(ediViewList), ([key, value]) => (
            { dropdownLabel: value, tagLabel: value, value: value }
        )
    ) ?? [], "dropdownLabel"))
];

const exceptionTypeList: { [key: string]: string; } = ReportConst.exceptionType;
export const exceptionTypeDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(exceptionTypeList), ([key, value]) => (
            { dropdownLabel: value, tagLabel: value, value: value }
        )
    ) ?? [], "dropdownLabel"))
];