import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { ChargeOnCompanyMappingProvider } from "presentation/store/ChargeOnCompanyMapping/ChargeOnCompanyMappingProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import ChargeOnCompanyMappingMaintenance from "presentation/view/section/ChargeOnCompanyMapping/ChargeOnCompanyMappingMaintenance";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const ChargeOnCompanyMappingContMain = () => {
    return <ANAInfoWrapper permission={Permission.CHARGE_ON_COMPANY_MAPPING_MAINTENANCE}>
        <MessageBarWrapper>
            <ChargeOnCompanyMappingProvider>
                <GridStyles/>
                <ChargeOnCompanyMappingMaintenance/>
            </ChargeOnCompanyMappingProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default ChargeOnCompanyMappingContMain;