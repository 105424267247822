import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { SpecialHandlingIndMappingEntity } from "domain/entity/SpecialHandlingIndMapping/SpecialHandlingIndMappingEntity";
import { axiosDeleteData, axiosGetData, axiosPostData, axiosPutData } from "../axios/AxiosBasicImpl";
import referenceAxiosInstance from "../axios/referenceAxiosInstance";
import { SpecialHandlingIndMappingRepository } from "./SpecialHandlingIndMappingRepo";

export const SpecialHandlingIndMappingRepoImpl = (): SpecialHandlingIndMappingRepository => {
    const url = '/v1/specialHandlingIndMapping';

    const getAllSpecialHandlingIndMappings = async() : Promise<SpecialHandlingIndMappingEntity[]> => {
        return axiosGetData(referenceAxiosInstance, `${url}`, []).then(res => {
            return res.data;;
        }).catch(err => {
            return [];
        });
    }

    const getSpecialHandlingIndMappingByKey = async(mappingType:string, specialHandlingCode: string): Promise<SpecialHandlingIndMappingEntity> => {
        return axiosGetData(referenceAxiosInstance, `${url}/${specialHandlingCode}`, []).then(res => {
            return res.data;
        }).catch(err => {
            return null;
        });
    }

    const createNewSpecialHandlingIndMapping = async(newData: SpecialHandlingIndMappingEntity) : Promise<SpecialHandlingIndMappingEntity> => {
        const result = axiosPostData(referenceAxiosInstance, `${url}`, newData).then(res => {
            return res.data;
        }).catch(err => {
            let errorMessage = "Update Special Handling Ind. Mapping Failure"
            if (err && err.response && err.response.data && typeof (err.response.data) === "string") {
                if (err.response.data.includes("already exist")) {
                    errorMessage = `"Special Handling Code ${newData.parameterDtlCode} Mapping" already exists.`
                }
            }
            throw new Error(errorMessage);
        });
        return result;
    }

    const updateSpecialHandlingIndMapping = async(updatedData: SpecialHandlingIndMappingEntity): Promise<SpecialHandlingIndMappingEntity> => {
        const result = axiosPutData(referenceAxiosInstance, `${url}`, updatedData).then(res => {
            return res.data
        }).catch(error => {
            return null
        })
        return result
    }

    const deleteSpecialHandlingIndMappingByKey = async(mappingType:string, specialHandlingCode: string): Promise<boolean> => {
        return axiosDeleteData(referenceAxiosInstance, `${url}`, specialHandlingCode).then(res => {
            return res.status === 200
        }).catch(error => {
            return false
        })
    }
    const onApply = async(rows: SpecialHandlingIndMappingEntity[]): Promise<ResponseEntity> => {
        const applyUrl = "/v1/specialHandlingIndMappingApply"
        return await axiosPostData(referenceAxiosInstance, `${applyUrl}`, rows);
    }

    const onSave = async(row: SpecialHandlingIndMappingEntity): Promise<ResponseEntity> => {
        const saveUrl = "/v1/specialHandlingIndMappingSave"
        return await axiosPostData(referenceAxiosInstance, `${saveUrl}`, row);
    }
    
    const searchAllSpecialHandlingIndMappingList = async() : Promise<SpecialHandlingIndMappingEntity[]> => {
        const searchUrl = "/v1/searchAllSpecialHandlingIndMappingList"
        return axiosGetData(referenceAxiosInstance,`${searchUrl}`,[]).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }

    return {
        searchAllSpecialHandlingIndMappingList: searchAllSpecialHandlingIndMappingList,
        onSave: onSave,
        onApply: onApply,
        getAllSpecialHandlingIndMappings: getAllSpecialHandlingIndMappings,
        getSpecialHandlingIndMappingByKey: getSpecialHandlingIndMappingByKey,
        createNewSpecialHandlingIndMapping: createNewSpecialHandlingIndMapping,
        updateSpecialHandlingIndMapping: updateSpecialHandlingIndMapping,
        deleteSpecialHandlingIndMappingByKey: deleteSpecialHandlingIndMappingByKey
    }
}