import { ConsortiumRepoImpl } from "domain/repository/ChargeDetailMaintenance/ConsortiumRepoImpl";
import { ChargeOnCompanyMappingRepoImpl } from "domain/repository/ChargeOnCompanyMapping/ChargeOnCompanyMappingRepoImpl";
import { ChargeTypeRepoImpl } from "domain/repository/ChargeType/ChargeTypeRepoImpl";
import { CompanyRepoImpl } from "domain/repository/Company/CompanyRepoImpl";
import { MasterDataRepoImpl } from "domain/repository/MasterData/MasterDataRepoImpl";
import { OpsLineRepoImpl } from "domain/repository/OpsLine/OpsLineRepoImpl";
import { ParameterDetailRepoImpl } from "domain/repository/parameter/ParameterDetailRepoImpl";
import { SpecialHandlingIndMappingRepoImpl } from "domain/repository/SpecialHanldingInd/SpecialHandlingIndMappingRepoImpl";
import { VesselTypeRepoImpl } from "domain/repository/VesselType/VesselTypeRepoImpl";
import { useChargeOnCompanyMappingTracked } from "presentation/store/ChargeOnCompanyMapping/ChargeOnCompanyMappingProvider";
import { ChargeOnCompanyMappingVM } from "presentation/viewModel/ChargeOnCompanyMapping/ChargeOnCompanyMappingVM";
import { useMemo } from "react";

export const useChargeOnCompanyMappingVM = () => {
    const [, setChargeOnCompanyMappingState] = useChargeOnCompanyMappingTracked();
    const chargeOnCompanyMappingVM = useMemo(() =>
        ChargeOnCompanyMappingVM({
            dispatch: [setChargeOnCompanyMappingState],
            chargeOnCompanyMappingRepo: ChargeOnCompanyMappingRepoImpl(),
            chargeTypeRepo: ChargeTypeRepoImpl(),
            specialhandlingIndMappingRepo: SpecialHandlingIndMappingRepoImpl(),
            opsLineRepo: OpsLineRepoImpl(),
            consortiumRepo: ConsortiumRepoImpl(),
            masterDataRepo: MasterDataRepoImpl(),
            companyRepo: CompanyRepoImpl(),
            parameterDetailRepo: ParameterDetailRepoImpl(),
            vesselTypeRepo: VesselTypeRepoImpl(),
        }), [setChargeOnCompanyMappingState])

    return chargeOnCompanyMappingVM
}

