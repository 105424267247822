import { ChargeOnCompanyMappingEntity } from "domain/entity/ChargeOnCompanyMapping/ChargeOnCompanyMappingEntity";
import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { axiosGetData, axiosPostData } from "../axios/AxiosBasicImpl";
import referenceAxiosInstance from "../axios/referenceAxiosInstance";
import { ChargeOnCompanyMappingRepository } from "./ChargeOnCompanyMappingRepo";

export const ChargeOnCompanyMappingRepoImpl = (): ChargeOnCompanyMappingRepository => {
    
    const searchUrl = "/v1/searchAllChargeOnCompanyMappingList"

    const searchAllChargeOnCompanyMappingList = async() : Promise<ChargeOnCompanyMappingEntity[]> => {
        
        return axiosGetData(referenceAxiosInstance,`${searchUrl}`,[]).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }
    
    const onApply = async(rows: ChargeOnCompanyMappingEntity[]): Promise<ResponseEntity> => {
        const applyUrl = "/v1/chargeOnCompanyMappingApply"
        return await axiosPostData(referenceAxiosInstance, `${applyUrl}`, rows);
    }

    const onSave = async(row: ChargeOnCompanyMappingEntity): Promise<ResponseEntity> => {
        const saveUrl = "/v1/chargeOnCompanyMappingSave"
        return await axiosPostData(referenceAxiosInstance, `${saveUrl}`, row);
    }


    return {
        onSave: onSave,
        onApply: onApply,
        searchAllChargeOnCompanyMappingList : searchAllChargeOnCompanyMappingList,
    }
}
